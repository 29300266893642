<template>
  <div class="index">
    <!-- <div class="header-logo">
        <i class="el-icon-s-custom" style="color: #1062d9"></i>
        <div class="line"></div>
        <span>个人中心</span>
      </div> -->
    <div class="mains">
      <el-container class="ccc">
        <el-aside class="el-aside">
          <div class="main-left">
            <el-collapse @change="getGoods" accordion>
              <el-collapse-item
                v-for="(item, index) in categoryList"
                :key="index"
                class="collapse-item"
                :title="$store.state.lang == 'en' ? item.e_name : item.name"
                :name="index"
              >
                <div
                  style="cursor: pointer"
                  v-for="(item1, index1) in item.child"
                  :key="index1"
                  :class="['list-item', item1.active ? 'list-active' : '']"
                  @click="tabClick(index, index1)"
                >
                  {{ $store.state.lang == 'en' ? item1.e_name : item1.name }}
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-aside>

        <el-main class="el-main ddd">
          <div class="main-right">
            <div class="goods" v-for="(item, index) in goodsList" :key="index">
              <div class="goods-left">
                <img :src="item.image" alt="" />
              </div>
              <div class="goods-right">
                <div>
                  {{ $store.state.lang == 'en' ? item.e_name : item.name }}
                </div>
                <div>{{ item.GoodsUnit }}</div>
                <div>
                  <div>
                    {{ $store.state.lang == 'en' ? '$' : '¥' }}{{ item.price }}
                  </div>
                  <div
                    style="cursor: pointer"
                    @click="openDialog(item)"
                    class="number"
                  >
                    <img src="@/assets/index_cart.png" alt="" />
                    <!-- <img @click="decrease" src="@/assets/decrease.png" alt="" />
                    <span>{{ count }}</span>
                    <img @click="increase" src="@/assets/increase.png" alt="" /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </div>
    <div class="paginations" style="display: flex; justify-content: flex-end">
      <el-pagination
        @current-change="ChangeGetGoods"
        background
        :current-page="params.page"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog :visible="centerDialogVisible" @close="closedialog" center>
      <div class="dialog-main">
        <!-- 头部 -->
        <div class="dialog-header">
          <div class="img">
            <el-image
              z-index="9999"
              :preview-src-list="imgList"
              style="width:100%,height:100%"
              :src="
                currentObj.colors ? currentObj.colors[xinghaoIndex].image : ''
              "
            >
            </el-image>
          </div>
          <div class="info">
            <div class="name">
              {{
                $store.state.lang == 'en' ? currentObj.e_name : currentObj.name
              }}
            </div>
            <div class="price">
              {{
                currentObj.colors ? currentObj.colors[xinghaoIndex].price : ''
              }}
            </div>
            <div class="type">
              {{
                currentObj.colors ? currentObj.colors[xinghaoIndex].xinghao : ''
              }}
            </div>
          </div>
        </div>
        <div class="dialog-body">
          <div class="name">
            {{ $store.state.lang == 'en' ? 'color' : '颜色' }}
          </div>

          <div class="color">
            <div
              style="cursor: pointer"
              v-for="(item, index) in currentObj.colors"
              :key="index"
              @click="changeSelectColor(index)"
              :class="['color-name', item.active ? 'color-active' : '']"
            >
              {{ $store.state.lang == 'en' ? item.e_color : item.color }}
            </div>
          </div>
        </div>
        <div class="goods-count">
          <div class="name">
            {{ $store.state.lang == 'en' ? 'num' : '数量' }}
          </div>
          <div class="count">
            <el-input-number
              v-model="num"
              @change="handleChange"
              :min="1"
              :precision="0"
              :max="1000000"
              label="描述文字"
            ></el-input-number>
          </div>
        </div>
        <div style="cursor: pointer" @click="addCart" class="button">
          {{ $store.state.lang == 'en' ? 'Add Cart' : '加入购物车' }}
        </div>
        <div style="height: 38px"></div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import { json } from 'body-parser'
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from 'element-china-area-data'
export default {
  name: 'User',
  data() {
    return {
      centerDialogVisible: false,
      count: 0,
      categoryList: [],
      goodsList: [],
      num: 1,
      params: {
        page: 1,
        code: 0,
        search: '',
      },
      total: 0,
      currentObj: {},
      xinghaoIndex: 0,
      imgList: [],
      imgLogo:{}
    }
  },
  created() {
    this.isshow = false
    this.getCategry()
    this.getGoodsList()


  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#ffffff'
    next()
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#ffffff'
    next()
  },
  methods: {
  
    openDialog(item) {
      console.log(item)
      this.currentObj = item
      this.xinghaoIndex = 0
      this.currentObj.colors.forEach((item, index) => {
        if (index == 0) {
          this.imgList[0] = item.image
          item.active = true
        } else {
          item.active = false
        }
      })

      this.centerDialogVisible = true
    },
    handleChange(value) {
      this.goodsNumber = value
    },
    async addCart() {
      let params = {}
      params.color = this.currentObj.colors[this.xinghaoIndex].color
      params.goods_id = this.currentObj.colors[this.xinghaoIndex].id
      params.type = 1
      params.num = this.num
      const { data: res } = await this.$http.post('api/user/add_cart', params)

      if (res.code == 1) {
        this.centerDialogVisible = false

        this.num = 1

        this.$message.success(res.msg)
        this.getCart()
      }
    },
    async getCart() {
      const { data: res } = await this.$http.post('api/user/get_cart')

      let nums = 0
      res.data.forEach((item) => {
        nums += item.num
      })
      // 把载荷和type分开提交
      this.$store.commit('increase', {
        num: nums,
      })

      localStorage.setItem('num', nums)
      this.$forceUpdate()
    },
    changeSelectColor(index) {
      this.xinghaoIndex = index
      this.currentObj.colors.forEach((item, index1) => {
        if (index1 == index) {
          item.active = true
          this.imgList[0] = item.image
        } else {
          item.active = false
        }
      })
      this.$forceUpdate()
    },
    closedialog() {
      this.centerDialogVisible = false
    },
    ChangeGetGoods(e) {
      this.params.page = e
      this.getGoodsList()
    },
    async getCategry() {
      const { data: res } = await this.$http.post('api/index/pcgetcategory')
      this.categoryList = res.data
      this.goodsList.forEach((item) => {
        if (item.child) {
          item.child.forEach((item1) => {
            item1.active = false
          })
        }
      })
    },
    getGoods(activeNames) {
      this.params.page = 1
      console.log(this.params.page);
      if (activeNames == '0') {
        this.params.code = 0
        
      } else {
        this.params.code = this.categoryList[activeNames].code
      }
      this.getGoodsList()
    },
    geta(value) {
      this.params.page = 1
      this.params.search = value
      this.getGoodsList()
    },
    async getGoodsList() {
      const { data: res } = await this.$http.post(
        'api/index/pcgetgoods',
        this.params
      )
      this.total = res.data.total
      this.goodsList = res.data.data
    },

    increase() {
      this.count++
    },
    decrease() {
      if (this.count <= 0) return this.$message.error('商品数量不可小于0')
      this.count--
    },
    tabClick(index, index1) {
      this.params.page = 1
      this.categoryList.forEach((item) => {
        if (item.child) {
          item.child.forEach((item1) => {
            item1.active = false
          })
        }
      })

      this.categoryList[index].child[index1].active = true
      this.params.code = this.categoryList[index].child[index1].code
      this.getGoodsList()
      this.$forceUpdate()
    },
  },
}
</script>
  
  <style lang="less" >
body {
  //   background: #ffffff;
}
.ccc {
  height: 615px;
}
.ddd {
  min-height: 800px;
}
.index {
  .el-dialog {
    width: 524px;
    // height: 441px;
    background: #ffffff;
    border-radius: 10px;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0 35px;
      .dialog-main {
        .dialog-header {
          display: flex;
          position: relative;
          left: -20px;
          // background: pink;
          .img {
            margin-top: 14px;
            width: 100px;
            height: 100px;
            // background: pink;
            border-radius: 6px;
            img {
              width: 100px;
              height: 100px;
              border-radius: 6px;
              //   background: pink;
            }
          }
          .info {
            margin-top: 14px;
            margin-left: 15px;
            .name {
              color: #333333;
              font-weight: 800;
              font-size: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box; //使用自适应布局
              -webkit-line-clamp: 2; //设置超出行数，要设置超出几行显示省略号就把这里改成几
              -webkit-box-orient: vertical;
            }
            .price {
              font-size: 25px;
              font-family: DINAlternate-Bold, DINAlternate;
              font-weight: bold;
              color: #333333;
            }
            .type {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }
        }
        .dialog-body {
          .name {
            margin: 16px 0;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          .color {
            display: flex;
            flex-wrap: wrap;

            .color-name {
              margin: 0 8px;
              //   padding: 0 56px;
              // min-width: 146px;
              text-align: center;
              // line-height: 36px;
              // height: 36px;
              padding: 10px 8px;
              background: #f6f6f6;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2f2f2f;
              border-radius: 2px;
              // margin-right: 8px;
              margin-bottom: 8px;
            }
            .color-active {
              background: #e6effb;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #035dd7;
            }
            .color-name:nth-child(3n) {
              margin-right: 0;
            }
          }
        }
        .goods-count {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 38px;
          .name {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
        .button {
          text-align: center;
          line-height: 54px;
          margin: 38px auto 0;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          width: 345px;
          height: 54px;
          background: #025edc;
          border-radius: 4px;
        }
      }
    }
  }
  // .dialog-main {

  // height: 441px;
  // background: #FFFFFF;
  // border-radius: 10px;
  // }
  //   dialog

  .paginations {
    margin: 62px 0;
  }
  .header-logo {
    width: 141px;
    margin: 34px 0 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    img {
      width: 22px;
      height: 22px;
      background: #025edc;
    }
    .line {
      width: 2px;
      height: 22px;
      background-color: #e2e2e2;
      transform: rotate(20deg);
    }
    span {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  //路由页

  .mains {
    overflow: hidden;
    margin-top: 45px;
    .el-aside {
      width: 284px !important;
      box-sizing: border-box !important;

      //   background: #e4ebf7;
      .collapse-item {
        background: #ffffff !important;
        margin-bottom: 5px;
        div > {
          background: transparent;
        }
        .el-collapse-item__content {
          padding: 0;
        }
        .list-item {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          padding-left: 18px;
          box-sizing: border-box;
          line-height: 58px;
          height: 58px;
          background: #eef3f9;
        }
        .list-active {
          color: #666666;
          background: #e5edfa;
        }
        .el-collapse-item__header {
          background: #e4ebf7;
          border-radius: 4px;
          align-items: center;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          padding-left: 18px;
        }
        .is-active {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;

          box-sizing: border-box;
          background: #1062d9;
          .is-active {
            margin-bottom: 0px;
            background: transparent;
          }
        }
      }
    }
    .el-main {
      margin-left: 20px;
      background: #ffffff;
      padding: 0;
      .main-right {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .goods {
        width: 394px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .goods-left {
          width: 100px;
          height: 100px;
          img {
            // background-color: pink;
            width: 100px;
            height: 100px;
          }
        }
        .goods-right {
          width: 280px;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          div:nth-child(1) {
            word-break: keep-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #393e4c;
          }
          div:nth-child(2) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
          div:nth-child(3) {
            font-size: 24px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #333333;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > div {
              font-size: 24px;
            }
            .number {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: DINAlternate-Bold, DINAlternate;
              img {
                width: 18px;
                height: 18px;
              }
              span {
                margin: 0 8px;
              }
            }
          }
        }
      }
    }
  }
}
</style>