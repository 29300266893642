<template>
  <div class="user">
    <!-- <div class="header-logo">
          <i class="el-icon-s-custom" style="color: #1062d9"></i>
          <div class="line"></div>
          <span>个人中心</span>
        </div> -->
    <div class="order-main">
      <el-container class="el-contai ddds">
        <el-aside class="el-aside">
          <div class="main-left">
            <div
              v-for="(item, index) in categoryList"
              :key="index"
              @click="changeCate(index)"
              :class="['tabs', item.active ? 'tabs-active' : '']"
            >
              <div class="tabs-name">
                {{ $store.state.lang == 'en' ? item.e_name : item.name }}
              </div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </el-aside>
        <!-- min-height: 800px -->
        <el-main style="" class="el-main ddds">
          <div class="top">
            <div style="display: flex; align-items: center" class="top-left">
              <el-checkbox v-model="checkAll" @change="handleCheckAllChange">
                {{ $store.state.lang == 'en' ? 'Select All' : '全选' }}
              </el-checkbox>
              <div style="margin-left: 10px">
                <div @click="xiazai" style="cursor: pointer" class="dowload">
                  <img src="@/assets/dowload.png" alt="" />
                  <span>{{
                    $store.state.lang == 'en' ? 'Download Excel' : '下载Excel'
                  }}</span>
                </div>
              </div>
            </div>
            <div class="top-right">
              <el-input
                style="cursor: pointer"
                v-model="value"
                class="borderNone"
                @input="selects"
                placeholder="search"
                prefix-icon="el-icon-search"
              >
              </el-input>
            </div>
          </div>
          <!-- orderList[goodsIndex] -->
          <div v-for="(item, index) in list" :key="index" class="main-right">
            <div class="main-header">
              <div class="header-left">
                <div class="order-info">
                  <el-checkbox
                    v-if="isshow"
                    @change="handleCheckedCitiesChange(item)"
                    :checked="item.ischecked"
                  ></el-checkbox>
                  <div style="width: 10px"></div>
                  <div class="time">{{ item.create_at }}</div>
                  <div class="order">
                    {{ $store.state.lang == 'en' ? 'Order Sn' : '订单号' }}：{{
                      item.order_sn
                    }}
                  </div>
                  <div v-if="item.express" class="order">
                    {{ item.express }} ： {{ item.express_number }}
                  </div>
                </div>
              </div>
              <div class="header-right">
                {{
                  $store.state.lang == 'en'
                    ? item.e_status_name
                    : item.status_name
                }}
              </div>
            </div>
            <div class="goods-list">
              <div>
                <div
                  class="goods"
                  v-for="(item1, index1) in item.sordergoods"
                  :key="index1"
                >
                  <div class="goods-left">
                    <img :src="item1.image" alt="" />
                  </div>
                  <div class="goods-right">
                    <div>
                      {{
                        $store.state.lang == 'en' ? item1.e_name : item1.name
                      }}
                    </div>
                    <div style="display: flex; align-items: center">
                      <div>
                        {{ item1.num }}
                        {{
                          $store.state.lang == 'en'
                            ? item1.EN_Units
                            : item1.Units
                        }}
                      </div>
                      <div style="margin-left: 10px">
                        {{
                          $store.state.lang == 'en'
                            ? item1.e_color
                            : item1.color
                        }}
                      </div>
                      <div class="sf" style="margin-left: 10px">
                        {{ item1.xinghao }}
                      </div>
                    </div>

                    <div>
                      <div>
                        {{ $store.state.lang == 'en' ? '$' : '¥'
                        }}{{ item1.price }}
                      </div>
                      <div class="number"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="price">
                {{ $store.state.lang == 'en' ? 'Total' : '合计' }}:
                <span
                  >{{ $store.state.lang == 'en' ? '$' : '¥'
                  }}{{ item.pay_money }}</span
                >
                <div class="hh" style="">
                  <div
                    style="cursor: pointer"
                    class="tuihui"
                    @click="gouwuche(item)"
                  >
                    {{
                      $store.state.lang == 'en' ? 'Return Cart' : '退回购物车'
                    }}
                  </div>
                  <div
                    style="cursor: pointer"
                    class="shanchu"
                    @click="shanchu(item)"
                  >
                    {{ $store.state.lang == 'en' ? 'Delete' : '删除' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </div>
  </div>
</template>
    
    <script>
import { json } from 'body-parser'
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from 'element-china-area-data'
export default {
  name: 'User',
  data() {
    return {
      value: '',
      categoryList: [],
      orderList: [],
      goodsIndex: 0,
      isshow: true,
      params: {
        search: '',
      },
      list: [],
      checkAll: false,
      checkedCities: [],

      isIndeterminate: true,
    }
  },
  async created() {
    // this.categoryList = []
    // this.orderList = []
    this.getorderlist()
    // this.$forceUpdate()
  },
  activated() {},
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#F6F6F6'
    next()
  },

  methods: {
    xiazai() {
      let arr = []
      this.list.forEach((item) => {
        if (item.ischecked == true) {
          arr.push(item.id)
        }
      })
      if (arr.length == 0) {
        return this.$message.error('请勾选需要下载的数据')
      }
      let params = arr.toString()
      let url = `https://1903.mzth.cn/api/index/order_excel?order_id=${params}&lang=ch`

      this.xia(url)
    },
    xia(url) {
      //获得id为downLoadListFrame的frame
      var divFrame = window.parent.document.getElementById('downLoadListFrame')
      //判断是否存在，如果存在先移除，再重新创建
      if (divFrame != null) {
        window.parent.document.body.removeChild(divFrame)
      }
      //重新创建
      var iframe = window.parent.document.createElement('iframe')
      iframe.setAttribute('id', 'downLoadListFrame')
      //download_file.id = "downFrame";
      window.parent.document.body.appendChild(iframe)
      divFrame = window.parent.document.getElementById('downLoadListFrame')
      //隐式调用，（注意：window.parent.document 适应对于，farme内部代码，调用frame 外部dom；如果项目未用frame，改写成 document即可）
      divFrame.src = url
      divFrame.style.display = 'none'
      this.orderList.forEach((item) => {
        item.forEach((item1) => {
          // console.log(item1)
          item1.ischecked = false
        })
      })
      this.list = []
      this.checkAll = false

      setTimeout(() => {
        this.list = this.orderList[this.goodsIndex]
      }, 1)
    },
    handleCheckAllChange(val) {
      if (val) {
        this.orderList[this.goodsIndex].forEach((item) => {
          item.ischecked = true
        })
      } else {
        this.orderList[this.goodsIndex].forEach((item) => {
          item.ischecked = false
        })
      }

      this.list = []
      setTimeout(() => {
        this.list = this.orderList[this.goodsIndex]
      }, 1)
      this.$forceUpdate()
    },
    handleCheckedCitiesChange(item) {
      item.ischecked = !item.ischecked
      let a = this.orderList[this.goodsIndex].findIndex((item) => {
        return item.ischecked == false
      })
      if (a == '-1') {
        this.checkAll = true
      } else {
        this.checkAll = false
      }

      // console.log(this.orderList[this.goodsIndex])
    },
    selects() {
      this.params.search = this.value
      this.getorderlist()
    },
    async getorderlist() {
      const { data: res } = await this.$http.post(
        'api/user/get_order',
        this.params
      )
      this.categoryList = []
      this.orderList = []
      for (let key in res.data) {
        this.categoryList.push({ name: key, active: false })
        this.orderList.push(res.data[key].data)
      }
      this.orderList.forEach((item) => {
        item.forEach((item1) => {
          if (item1.status_name == '审核中') {
            item1.e_status_name = 'Under review'
          } else if (item1.status_name == '待付款') {
            item1.e_status_name = 'To be paid'
          } else if (item1.status_name == '待发货') {
            console.log('dai')
            item1.e_status_name = 'To be shipped'
          } else if (item1.status_name == '运输中') {
            console.log('yun')
            item1.e_status_name = 'In transit'
          } else if (item1.status_name == '已完成') {
            item1.e_status_name = 'Completed'
          }
          item1.ischecked = false
        })
      })
      let arrs = []
      this.categoryList.forEach((item, index) => {
        let e_name = ''
        if (index == 0) {
          e_name = ' All'
        } else if (index == 1) {
          e_name = 'Under review'
        } else if (index == 2) {
          e_name = 'To be paid'
        } else if (index == 3) {
          e_name = 'To be shipped'
        } else if (index == 4) {
          e_name = 'In transit'
        } else if (index == 5) {
          e_name = 'Completed'
        }
        arrs.push({ e_name, active: false, name: item.name })
      })
      this.categoryList = arrs
      this.list = []
      this.checkAll = false

      setTimeout(() => {
        this.list = this.orderList[this.goodsIndex]
      }, 1)
      // this.list = this.orderList[this.goodsIndex]
      this.categoryList[this.goodsIndex].active = true
      // this.$forceUpdate()
    },
    changeCate(index) {
      this.getorderlist()
      this.categoryList.forEach((item) => {
        item.active = false
      })

      // this.orderList.forEach((item) => {
      //   item.forEach((item1) => {
      //     item1.ischecked = false
      //   })
      // })

      this.categoryList[index].active = true
      // this.list = []
      // this.checkAll = false
      // setTimeout(() => {
      //   this.list = this.orderList[index]
      // }, 100)
      // this.$forceUpdate()
      this.goodsIndex = index
    },
    gouwuche(item) {
      const content =
        this.$store.state.lang == 'en'
          ? 'Whether to confirm the return'
          : '是否确认退回'

      const b1 = this.$store.state.lang == 'en' ? 'sure' : '确定'
      const b2 = this.$store.state.lang == 'en' ? 'cancel' : '取消'
      this.$confirm(content, '', {
        confirmButtonText: b1,
        cancelButtonText: b2,
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.post(
            '/api/user/tuihui_order',
            {
              order_sn: item.order_sn,
            }
          )
          if (res.code == 1) {
            this.getorderlist()
            this.$message.success(res.msg)
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退回',
          })
        })
    },
    shanchu(item) {
      const content =
        this.$store.state.lang == 'en'
          ? 'Whether to confirm the delete'
          : '是否确认删除'

      const b1 = this.$store.state.lang == 'en' ? 'sure' : '确定'
      const b2 = this.$store.state.lang == 'en' ? 'cancel' : '取消'
      this.$confirm(content, '', {
        confirmButtonText: b1,
        cancelButtonText: b2,
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.post('/api/user/del_order', {
            order_sn: item.order_sn,
          })
          if (res.code == 1) {
            this.getorderlist()
            this.$message.success(res.msg)
          }
          //   this.$message({
          //     type: 'success',
          //     message: '删除成功!'
          //   });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>
    
    <style lang="less" >
body {
  background: #ffffff;
}
.ddds {
  height: 800px;
}
.cccs {
  height: 615px;
}
.el-checkbox__inner::after {
  height: 12px;
  left: 6px;

  top: 0px;

  width: 4px;
}
.hh {
  right: 15px;
  position: absolute;
  bottom: 20px;
  display: flex;
}
.top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .top-left {
    padding-left: 15px;
  }
  .top-right {
    margin-right: 20px;
    width: 345px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    // border: 1px solid #e5e5e5;
  }
}

.user {
  .el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  .dowload {
    // min-width: 95px;
    justify-content: space-between;
    padding: 6px 10px;
    height: 27px;
    background: #025edc;
    border-radius: 1px;
    display: flex;
    align-items: center;

    img {
      width: 14px;
      height: 14px;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .shanchu,
  .tuihui {
    padding: 0 16px;
    margin-right: 4px;
    text-align: center;
    line-height: 26px;
    height: 26px;
    border-radius: 16px;
    border: 1px solid #4b83f0;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4b83f0;
  }
  .order-main {
    margin-top: 20px;
    .el-aside {
      width: 155px !important;
      box-sizing: border-box !important;
      .main-left {
        .tabs {
          width: 155px;
          height: 48px;
          display: flex;
          margin-bottom: 5px;
          padding: 0 20px;
          box-sizing: border-box;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2f2f2f;
          align-items: center;
          justify-content: space-between;
          background: #f5f5f5;
        }
        .tabs-active {
          background: rgba(78, 134, 237, 0.2);

          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #548aee;
        }
      }
    }
    .el-contai {
      background: #ffffff;
      padding: 25px;
      box-sizing: border-box;
    }
    .el-main {
      margin-left: 20px;
      background: #ffffff;
      padding: 0;
      width: 155px;

      .main-right {
        background: #f5f5f5;
        .main-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 35px;
          background: #f7f7f7;
          .header-right {
            padding-right: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4b83f0;
          }
          .header-left {
            padding-left: 15px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 669px;
            height: 35px;
            border-right: 1px solid #ededed;
            .order-info {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .time {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              }
              .order {
                padding-right: 1px;
                margin-left: 16px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #666666;
              }
            }
          }
        }
        .goods-list {
          position: relative;
          width: 100%;
          background: #ffffff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .price {
            margin-right: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #393e4c;
            span {
              font-size: 18px;
              font-weight: 600;
            }
          }
          .goods {
            background: #ffffff;
            width: 669px;
            display: flex;

            border-right: 1px solid #ededed;
            border-left: 1px solid #ededed;
            border-top: 1px solid #ededed;
            padding: 15px;
            // margin-bottom: 30px;
            .goods-left {
              width: 76px;
              height: 76px;
              img {
                // background-color: pink;
                width: 76px;
                height: 76px;
              }
            }
            .goods-right {
              width: 460px;
              margin-left: 10px;
              // height: 76px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .sf {
                font-size: 16px !important;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #333333;
              }
              div:nth-child(1) {
                // word-break: keep-all;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #393e4c;
              }
              div:nth-child(2) {
                padding-top: 3px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
              }
              div:nth-child(3) {
                font-size: 24px;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #333333;
                span {
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #999999;
                }
                display: flex;
                justify-content: space-between;
                align-items: center;
                > div {
                  font-size: 24px;
                }
                .number {
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  font-family: DINAlternate-Bold, DINAlternate;
                  img {
                    width: 18px;
                    height: 18px;
                  }
                  span {
                    margin: 0 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>