<template>
  <div class="user">
    <div class="header-logo">
      <i class="el-icon-s-custom" style="color: #1062d9"></i>
      <div class="line"></div>
      <div class="cc" style="display: flex; flex-wrap: nowrap">
        {{ $store.state.lang == 'en' ? 'Personal Center' : '个人中心' }}
      </div>
    </div>
    <div class="main">
      <el-container class="aaa">
        <el-aside class="el-aside">
          <div class="main-left">
            <ul>
              <li style="cursor: pointer" @click="tabClick(1)">
                {{ $store.state.lang == 'en' ? 'personal data' : '个人资料' }}

                <img
                  v-if="activeIndex == 1"
                  src="@/assets/left-icon.png"
                  alt=""
                />
              </li>
              <li style="cursor: pointer" @click="tabClick(2)">
                {{
                  $store.state.lang == 'en' ? 'shipping address' : '收货地址'
                }}

                <img
                  v-if="activeIndex == 2"
                  src="@/assets/left-icon.png"
                  alt=""
                />
              </li>
              <!-- <li @click="tabClick(3)">
                用户信息
                <img
                  v-if="activeIndex == 3"
                  src="@/assets/left-icon.png"
                  alt=""
                />
              </li> -->
              <li style="cursor: pointer" @click="tabClick(4)">
                {{ $store.state.lang == 'en' ? 'change Password' : '修改密码' }}

                <img
                  v-if="activeIndex == 4"
                  src="@/assets/left-icon.png"
                  alt=""
                />
              </li>
            </ul>
          </div>
        </el-aside>

        <el-main class="el-main">
          <div class="main-right">
            <!-- 个人资料 -->
            <div v-if="activeIndex == 1" class="gerenziliao">
              <div class="user-data">
                <span>
                  {{
                    $store.state.lang == 'en' ? 'personal data' : '个人资料'
                  }}</span
                >
                <el-divider></el-divider>
                <div class="userinfo">
                  <div class="user-photo-name">
                    <div class="photo">
                      <img
                        style="width: 100%; height: 100%; border-radius: 50%"
                        :src="url"
                        alt=""
                      />
                      <!-- <el-avatar :size="70" :src="url"></el-avatar> -->
                    </div>
                    <div class="name">{{ userInfo.nickname }}</div>
                  </div>
                  <div class="line"></div>
                  <div class="form-item">
                    <span>
                      {{ $store.state.lang == 'en' ? 'account' : '账号' }}</span
                    >
                    {{ userInfo.username }}
                  </div>
                  <div class="line"></div>
                  <!-- <div class="form-item">
                    <span>密码</span>
                    12345678
                  </div> -->
                  <!-- <div class="line"></div> -->
                </div>
              </div>
              <div @click="outLogin" class="aside-out">
                <div class="out-login">
                  {{ $store.state.lang == 'en' ? 'log out' : '退出登录' }}
                </div>
              </div>
            </div>
            <!-- 地址 -->
            <div v-if="activeIndex == 2" class="address">
              <span>
         
                {{ $store.state.lang == 'en' ? 'shipping address' :'收货地址'  }}
              </span>
              <el-divider></el-divider>
              <!-- @click="addAdress" -->
              <div
                style="cursor: pointer"
                @click="openDialog()"
                class="add-address"
              >
                <span>
                  {{
                    $store.state.lang == 'en'
                      ? 'Add a new address'
                      : ' 添加新地址'
                  }}
                  <!-- 添加新地址   -->
                </span>
                <img src="@/assets/add_address.png" alt="" />
              </div>
              <div
                v-for="(item, index) in addressList"
                :key="index"
                class="adress-content"
              >
                <div class="content-item">
                  <div>
                    <span class="key">{{ $store.state.lang == 'en' ? 'Region' : '所在地区' }}</span>
                    <span class="value">{{ item.consignee }}</span>
                    <span v-if="item.is_default == 1" class="ismoren"
                      >{{ $store.state.lang == 'en' ? 'Default address' : '默认地址' }}</span
                    >
                  </div>
                  <div style="cursor: pointer" @click="deleteAddress(item.id)">
                    <img src="@/assets/shanchu.png" alt="" />
                  </div>
                </div>
                <div class="content-item">
                  <span class="key">{{ $store.state.lang == 'en' ? 'Phone' : '手机号码' }}</span>
                  <span class="value">{{ item.phone }}</span>
                </div>
                <div class="content-item">
                  <span class="key">{{ $store.state.lang == 'en' ? 'Region' : '所在地区' }}</span>
                  <span class="value"
                    >{{ item.province_name }}
                    {{
                      item.city_name == item.province_name ? '' : item.city_name
                    }}
                    {{ item.area_name }}</span
                  >
                </div>
                <div class="content-item">
                  <div>
                    <span class="key">{{ $store.state.lang == 'en' ? 'Full address' : '详细地址' }}</span>
                    <span class="value">{{ item.address }}</span>
                  </div>
                  <div class="bianji">
                    <span
                      style="cursor: pointer"
                      @click="editAddress(item)"
                      v-if="item.is_default != 1"
                      >{{ $store.state.lang == 'en' ? 'Set as default' : '设为默认' }}</span
                    >
                    <span style="cursor: pointer" @click="openDialog(1, item)"
                      >{{ $store.state.lang == 'en' ? 'edit' : '编辑' }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- 修改密码 -->
            <div v-if="activeIndex == 4" class="xiumima">
              <span>
                {{
                  $store.state.lang == 'en' ? 'change Password' : '修改密码'
                }}</span
              >
              <el-divider></el-divider>

              <div style="margin-top: 50px" class="password">
                <div class="label">
                  {{
                    $store.state.lang == 'en' ? 'Original password' : '原密码'
                  }}
                </div>
                <div class="input">
                  <el-input
                    :placeholder="
                      $store.state.lang == 'en' ? 'Original password' : '原密码'
                    "
                    v-model="yuanpassword"
                  >
                  </el-input>
                </div>
              </div>
              <div class="password">
                <div class="label">
                  {{ $store.state.lang == 'en' ? 'New password' : '新密码' }}
                </div>
                <div class="input">
                  <el-input
                    :placeholder="
                      $store.state.lang == 'en' ? 'New password' : '新密码'
                    "
                    v-model="newpassword1"
                  >
                  </el-input>
                </div>
              </div>
              <div class="password">
                <div class="label">
                  {{
                    $store.state.lang == 'en'
                      ? 'Confirm the new password'
                      : '确认新密码'
                  }}
                </div>
                <div class="input">
                  <el-input
                    :placeholder="
                      $store.state.lang == 'en'
                        ? 'Confirm the new password'
                        : '确认新密码'
                    "
                    v-model="newpassword"
                  >
                  </el-input>
                </div>
              </div>

              <div class="password">
                <div class="label"></div>
                <div class="input">
                  <div @click="editPassword" class="button">
                    {{ $store.state.lang == 'en' ? 'Confirm' : '确认' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </div>
    <el-dialog
      :title="$store.state.lang == 'en' ? 'Add a new address' : '添加新地址'"
      :visible="centerDialogVisible"
      @close="closedialog"
      width="56%"
      center
    >
      <div class="xiumimas">
        <div class="password">
          <div class="label">
            {{ $store.state.lang == 'en' ? 'Region' : '所在地区' }}
          </div>
          <div class="input">
            <el-cascader
              style="width: 100%"
              :placeholder=" $store.state.lang == 'en' ? 'Please select' : '请选择'"
              :options="options"
              v-model="selectedOptions"
              @change="addressChoose"
            ></el-cascader>
            <!-- <el-input placeholder="请选择"   suffix-icon="el-icon-arrow-down" v-model="yuanpassword"> </el-input> -->
          </div>
        </div>
        <div class="password">
          <div class="label">  {{ $store.state.lang == 'en' ? 'Recipient' : '收 件 人' }}</div>
          <div class="input">
            <el-input :placeholder=" $store.state.lang == 'en' ? 'Please enter' : '请输入'" v-model="address.consignee">
            </el-input>
          </div>
        </div>
        <div class="password">
          <div class="label">{{ $store.state.lang == 'en' ? 'phone' : '手机号码' }}</div>
          <div class="input">
            <el-input :placeholder=" $store.state.lang == 'en' ? 'Please enter' : '请输入'" v-model="address.phone">
            </el-input>
          </div>
        </div>
        <div class="password">
          <div class="label">{{ $store.state.lang == 'en' ? 'Full address' : '详细地址' }}</div>
          <div class="input">
            <el-input
              type="textarea"
              :rows="4"
              resize="none"
              :placeholder=" $store.state.lang == 'en' ? 'Please enter' : '请输入'"
              v-model="address.address"
            >
            </el-input>
          </div>
        </div>
        <div class="password button">
          <div class="label"></div>
          <div @click="addAddress" class="input">
            <el-button class="el-buttons" type="primary">{{ $store.state.lang == 'en' ? 'Save' : '保存' }}</el-button>
          </div>
        </div>
      </div>

      <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
    </el-dialog>
  </div>
</template>

<script>
import { json } from 'body-parser'
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from 'element-china-area-data'
export default {
  name: 'User',
  data() {
    return {
      isclose: false,
      userInfo: JSON.parse(localStorage.getItem('userinfo')),
      centerDialogVisible: false,
      addressList: [],
      activeIndex: 1,
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      yuanpassword: '',
      newpassword: '',
      newpassword1: '',
      options: regionData,
      selectedOptions: [],
      form: { province: '', city: '', area: '' },
      form1: { province: '', city: '', area: '' },
      address: {
        province_name: '',
        city_name: '',
        area_name: '',
        address: '',
        phone: '',
        consignee: '',
        is_default: '1',
      },
      address1: {
        province_name: '',
        city_name: '',
        area_name: '',
        address: '',
        phone: '',
        consignee: '',
        is_default: '1',
      },
    }
  },
  created() {
    this.getList()

    console.log(regionData)
    regionData.push({
      label: 'Overseas address',
      value: '1',
    })
    if (this.$route.query.key) {
      this.activeIndex = this.$route.query.key
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f6f6f6'
    next()
  },
  methods: {
    bianjiAddress(item) {},

    async addAddress(index) {
      //   Object.keys(this.address).foreach((key) => {
      //     this.address[key] = ''
      //   })
      if (this.index == 1) {
        this.address.province_name = this.form.province
        this.address.city_name =
          this.form.city == '市辖区' ? this.form.province : this.form.city
        this.address.area_name = this.form.area
        const { data: res } = await this.$http.post(
          'api/user/add_address',
          this.address
        )
        if (res.code == 1) {
          this.form = JSON.parse(JSON.stringify(this.form1))
          this.address = JSON.parse(JSON.stringify(this.address1))
          this.selectedOptions = []
          this.getList()
          this.centerDialogVisible = false
        } else {
          this.$message.error(res.msg)
        }
      } else {
        if (this.selectedOptions.length == 0) {
        } else {
          this.address.province_name = this.form.province
          this.address.city_name =
            this.form.city == '市辖区' ? this.form.province : this.form.city
          this.address.area_name = this.form.area
        }
        const { data: res } = await this.$http.post(
          'api/user/edit_address',
          this.address
        )
        if (res.code == 1) {
          this.form = JSON.parse(JSON.stringify(this.form1))
          this.address = JSON.parse(JSON.stringify(this.address1))
          this.selectedOptions = []
          this.getList()
          this.centerDialogVisible = false
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    addressChoose(value) {
      console.log(value)
      // console.log(
      //   '省市区：',
      //   CodeToText[value[0]],
      //   CodeToText[value[1]],
      //   CodeToText[value[2]]
      // )
      if (value[0] == 1) {
        this.form.province = CodeToText[value[0]] = 'Overseas address'
        this.form.city = ''
        this.form.area = ''
      } else {
        this.form.province = CodeToText[value[0]]
        this.form.city = CodeToText[value[1]]
        this.form.area = CodeToText[value[2]]
        let province = this.form.province
        let city = this.form.city
        let area = this.form.area
        let arg1 = TextToCode[province].code
        let arg2 = TextToCode[province][city].code
        let arg3 = TextToCode[province][city][area].code
        this.selectedOptions.push(arg1, arg2, arg3)
      }
    },
    openDialog(index, item) {
      if (index == 1) {
        this.index = 2

        this.address = JSON.parse(JSON.stringify(item))
      } else {
        this.index = 1
      }
      this.centerDialogVisible = true
    },
    closedialog() {
      this.form = JSON.parse(JSON.stringify(this.form1))
      this.address = JSON.parse(JSON.stringify(this.address1))
      this.selectedOptions = []
      this.centerDialogVisible = false
    },
    async editPassword() {
      if (this.yuanpassword == '')
        return this.$message.error('原密码不允许为空')
      if (this.newpassword1 == '')
        return this.$message.error('新密码不允许为空')
      if (this.newpassword1 != this.newpassword)
        return this.$message.error('两次密码不一致')
      const { data: res } = await this.$http.post('api/user/changepwd', {
        newpassword: this.newpassword,
      })
      if (res.code == 1) {
        this.$message.success(res.msg)
        this.newpassword = ''
        this.newpassword1 = ''
        this.yuanpassword = ''
      } else {
        this.$message.error(res.msg)
      }
    },
    async deleteAddress(id) {
      const content = this.$store.state.lang == 'en' ? 'Whether to confirm the deletion of the address' : '是否确认删除该地址'
      const b1 =  this.$store.state.lang == 'en' ? 'sure' : '确定'
      const b2 =  this.$store.state.lang == 'en' ? 'cancel' : '取消'
      this.$confirm( content, '', {
        confirmButtonText: b1,
        cancelButtonText: b2,
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.post('api/user/del_address', {
            id,
          })
          if (res.code == 1) {
            this.$message.success(res.msg)
            this.getList()
          }
          //   this.$message({
          //     type: 'success',
          //     message: '删除成功!'
          //   });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    async editAddress(item) {
      let params = item
      params.is_default = 1
      const { data: res } = await this.$http.post(
        'api/user/edit_address',
        params
      )
      if (res.code == 1) {
        this.$message.success(res.msg)
        this.getList()
      }
    },
    async getList() {
      const { data: res } = await this.$http.post('api/user/get_address')
      this.addressList = res.data
    },
    tabClick(index) {
      this.activeIndex = index
    },
    outLogin() {
      localStorage.removeItem('token')
      localStorage.removeItem('userinfo')
      this.$router.push({ path: 'login' })
    },
  },
}
</script>

<style lang="less" scoped>
.user {
  .aaa {
    height: 615px;
  }
  .header-logo {
    width: 241px;
    margin: 34px 0 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    img {
      width: 22px;
      height: 22px;
      background: #025edc;
    }
    .line {
      display: block;
      margin-right: 20px;
      margin-left: 20px;
      width: 2px;
      height: 22px;
      background-color: #e2e2e2;
      transform: rotate(20deg);
    }
    .cc {
      flex: 1;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .main {
    display: flex;
    justify-content: space-between;
    .el-main {
      width: 1008px;
      //   padding: 0;
      margin-left: 12px;
      background: #ffffff;
      border-radius: 4px;
      .el-divider--horizontal {
        margin-top: 9px !important;
      }
    }
    .el-aside {
      width: 180px !important;
      border-radius: 4px;
    }
    .main-right {
      span {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .userinfo {
        .user-photo-name {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          .photo {
            width: 70px;
            height: 70px;
            border-radius: 50%;
          }
          .name {
            margin-left: 17px;
          }
        }
        .line {
          border-top: 1px dashed #dcdfe6;
        }
        .form-item {
          padding: 19px 0;
          font-size: 14px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #333333;
          display: flex;
          align-items: center;
          span {
            display: block;
            width: 87px;
            font-size: 14px;
            font-family: SourceHanSansSC-Regular, SourceHanSansSC;
            font-weight: 400;
            color: #666666;
          }
        }
      }
      .aside-out {
        display: flex;
        justify-content: flex-end;
        .out-login {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 14px;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #ffffff;
          width: 162px;
          margin-right: 30px;
          margin-top: 140px;
          height: 52px;
          background: #333333;
          border-radius: 4px;
        }
      }
    }
    // 地址
    .add-address {
      max-width: 285px;
      padding: 0 20px;
      position: relative;
      // height: 54px;
      img {
        width: 100%;
        height: 100%;
      }
      span {
        top: 50%;
        transform: translateY(-50%);
        right: 45px;
        position: absolute;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
    .adress-content {
      margin-top: 10px;
      width: 910px;
      height: 118px;
      border-radius: 4px;
      border: 1px solid #cdcdcd;
      padding: 20px;
      .content-item {
        height: 22px;
        display: flex;
        div:nth-child(2) {
          flex: 1;
          text-align: right;
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4b83f0;
          }
          span:nth-child(2) {
            margin-left: 30px;
          }
          img {
            width: 12px;
            height: 12px;
          }
        }
        .key {
          display: inline-block;
          text-align: right;
          min-width: 76px;
          height: 12px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 12px;
        }
        .value {
          margin-left: 10px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2f2f2f;
        }
        > div {
          .ismoren {
            padding: 0 5px;
            margin-left: 6px;
            width: 59px;
            height: 22px;
            border-radius: 2px;
            border: 1px solid #4b83f0;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4b83f0;
          }
        }
      }
    }

    .xiumima {
      .buttons {
        margin-top: 50px;
      }

      .password {
        justify-content: center;
        display: flex;
        align-items: center;
        height: 48px;

        .label {
          min-width: 200px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2f2f2f;
          text-align: right;
        }
        .input {
          margin-left: 17px;
          width: 344px;
          .button {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #f9f4e4;
            margin-top: 48px;
            text-align: center;
            line-height: 55px;
            width: 344px;
            height: 55px;
            background: #4b83f0;
            border-radius: 4px;
          }
        }
      }
    }
    .main-left {
      border-radius: 4px;
      background: #ffffff;
      border-radius: 4px;
      text-align: center;
      ul {
        li {
          position: relative;
          padding: 26px 0;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          color: #666666;
          img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 20px;
          }
          .active {
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }
  }
  .el-buttons {
    width: 344px;
    height: 55px;
    background: #4b83f0;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #f9f4e4;
  }
  .xiumimas {
    .button {
      margin: 40px 0 0 !important;
    }
    .password {
      justify-content: center;
      display: flex;
      align-items: center;
      min-height: 48px;
      margin-top: 15px;
      .label {
        min-width: 80px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2f2f2f;
        text-align: right;
      }
      .input {
        margin-left: 17px;
        width: 344px;
        .button {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #f9f4e4;

          text-align: center;
          line-height: 55px;
          width: 344px;
          height: 55px;
          background: #4b83f0;
          border-radius: 4px;
        }
      }
    }
  }
}
body {
  background: #f6f6f6;
}
</style>