<template>
  <div class="cart">
    <div class="header-logo">
      <i class="el-icon-s-custom" style="color: #1062d9"></i>
      <div class="line"></div>
      <span>{{ $store.state.lang == 'en' ? 'Cart' : '购物车' }}</span>
      <div class="line"></div>
      <span>{{
        $store.state.lang == 'en' ? 'Confirm the order' : '确认订单'
      }}</span>
    </div>

    <div class="addres">
      <div style="margin-bottom: 10px">
        {{ $store.state.lang == 'en' ? 'Receiving information' : '确收件信息' }}
      </div>
      <div class="address-info">
        <div style="cursor: pointer" @click="toaddres" class="add-info">
          <div>
            <i class="el-icon-plus"></i>
          </div>
          <div>     {{
                      $store.state.lang == 'en'
                        ? 'Add a new address'
                        : ' 添加新地址'
                    }}</div>
        </div>
        <div
          style="cursor: pointer"
          v-for="(item, index) in addressList"
          @click="changeaddres(index)"
          :key="index"
          :class="['info-item', item.is_default == 1 ? 'active-info ' : '']"
        >
          <div>{{ item.consignee }}</div>

          <div>{{ item.phone }}</div>
          <div>
            {{ item.province_name }}
            {{ item.city_name == item.province_name ? '' : item.city_name }}
            {{ item.area_name }}
          </div>
          <div class="detail">{{ item.address }}</div>
        </div>
      </div>
    </div>

    <div style="width: 100%">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="selection" width="80"> </el-table-column> -->
        <el-table-column width="600">
          <template slot-scope="scope">
            <div class="img">
              <div class="imgs"><img :src="scope.row.image" alt="" /></div>
              <div class="right">
                <div class="name">
                  {{
                    $store.state.lang == 'en'
                      ? scope.row.e_name
                      : scope.row.name
                  }}
                </div>
                <div style="display: flex; margin-top: 20px" class="color">
                  <div>
                    {{ scope.row.num }}
                 
                    {{
                      $store.state.lang == 'en'
                        ? scope.row.EN_Units
                        : scope.row.Units
                    }}
                  </div>
                  <div style="margin-left: 10px">
                    {{
                      $store.state.lang == 'en'
                        ? scope.row.e_color
                        : scope.row.color
                    }}
                  </div>
                  <div class="sf" style="margin-left: 10px">
                    {{ scope.row.xinghao }}
                  </div>
                  <!-- <span>{{ scope.row.color }}</span> -->
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="200">
          <template slot-scope="scope">
            <div>
              <span class="a1"
                >{{ $store.state.lang == 'en' ? '$' : '¥' }}
                {{ scope.row.price }}
              </span>
              <span class="a2"
                >X{{ scope.row.num }}
              </span
              >
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="数量" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                <el-input-number
                  @change="
                    (val, val1) => {
                      handleChange(val, val1, scope.row)
                    }
                  "
                  @focus="handleChange"
                  v-model="scope.row.num"
                  :min="1"
                  :precision="0"
                  :max="1000000"
                  label="描述文字"
                ></el-input-number>
              </div>
            </template>
          </el-table-column> -->
        <el-table-column align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- @change="handleChange" -->
            <div class="aa">
              {{ $store.state.lang == 'en' ? '$' : '¥' }}
              {{ (scope.row.num * scope.row.price) | numFilter }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="操作" show-overflow-tooltip>
            <template slot-scope="scope">
              <img
                @click="deletecart(scope.row.id)"
                class="img-class"
                src="@/assets/e-delete.png"
                alt=""
              />
            </template>
          </el-table-column> -->
      </el-table>
    </div>
    <div class="input">
      <input
        v-model="ext"
        :placeholder="$store.state.lang == 'en' ? 'Remarks' : '备注'"
      />
    </div>
    <div class="bottom-content">
      <div class="content-left"></div>
      <div class="content-right">
        <div class="right-info">
          <!-- <div class="right-num" @click="deletecart(0)">  <el-button type="primary">清空购物车</el-button></div> -->
          <div class="right-price">
            {{ $store.state.lang == 'en' ? 'Total' : '合计' }}
            <span>
              {{ $store.state.lang == 'en' ? '$' : '¥'
              }}{{ money | numFilter }}</span
            >
          </div>
        </div>
        <div class="right-action">
          <div style="cursor: pointer" @click="tijiao" class="button">
            {{ $store.state.lang == 'en' ? 'Submit' : '下单' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import { json } from 'body-parser'
// import {resetRouter} from '../../router/index.js';

export default {
  name: 'User',
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      ext: '',
      money: 0,
      addressList: [],
    }
  },
  created() {
    // this.getCart()
    // this.getList()
  },

  activated() {
    this.getCart()
    this.getList()
  },
  updated() {},
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f6f6f6'
    next()
  },

  methods: {
    async tijiao() {
      let params = {}
      this.addressList.forEach((item) => {
        if (item.is_default == 1) {
          params.address_id = item.id
        }
      })
      params.ext = this.ext
      const { data: res } = await this.$http.post('api/user/add_order', params)

      if (res.code == 1) {
        this.$message.success(res.msg)

        this.getCarts()
        this.$router.push({ path: '/index' })

        this.$forceUpdate()
      }
    },
    async getCarts() {
      const { data: res } = await this.$http.post('api/user/get_cart')

      let nums = 0
      res.data.forEach((item) => {
        nums += item.num
      })

      this.$store.commit('increase', {
        num: nums,
      })

      localStorage.setItem('num', nums)
    },

    toaddres() {
      this.$router.push({ path: '/user', query: { key: 2 } })
    },
    changeaddres(index) {
      this.addressList.forEach((item) => {
        item.is_default = 0
      })
      this.addressList[index].is_default = 1
      this.$forceUpdate()
    },
    async getList() {
      const { data: res } = await this.$http.post('api/user/get_address')
      this.addressList = res.data
    },
    async getnum() {
      const { data: res } = await this.$http.post('api/user/cart_money')

      this.money = res.data.money
    },
    async toAddOrders() {
      let params = {}
      params.ext = this.ext
      const { data: res } = await this.$http.post('api/user/del_cart', params)

      if (res.code == 1) {
        this.$message.success(res.msg)
        this.getCart()
      }
    },

    async getCart() {
      const { data: res } = await this.$http.post('api/user/get_cart')
      this.tableData = res.data
      this.getnum()
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2)
      return realVal
    },
  },
}
</script>
    
    <style lang="less" scoped>
/deep/ .el-table .cell {
  line-height: normal;
}
/deep/ table thead .el-table_1_column_2 .cell {
  text-align: center !important;
}
.cart {
  .ddds {
    height: 900px;
  }
  .addres {
    padding: 23px;
    width: 100%;
    background: #eef3f9;
    border-radius: 2px;
    div {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .address-info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .add-info {
        margin-right: 15px;
        margin-bottom: 8px;
        i {
          color: #cdcdcd;
          font-size: 50px;
        }
        width: 128px;
        height: 128px;
        border-radius: 4px;
        border: 1px solid #cdcdcd;
        text-align: center;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        div {
          color: #cdcdcd;
        }
        color: #cdcdcd;
      }
      .info-item {
        margin-bottom: 8px;
        margin-right: 15px;
        width: 128px;
        height: 128px;
        border-radius: 4px;
        border: 1px solid #cdcdcd;
        padding: 0 12px;
        box-sizing: border-box;
        div {
          margin-top: 4px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
      .active-info {
        border: 1px solid #4b83f0;
        div {
          color: #333333;
        }
      }
    }
  }
  .input {
    margin-top: 20px;
    height: 80px;
    background: #ffffff;
    border-radius: 2px;
    input {
      padding-left: 30px;
      border-radius: 2px;
      font-size: 18px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #cccccc;
      width: 100%;
      height: 100% !important;
    }
  }
  .detail{
    overflow: hidden; //超出文本隐藏

text-overflow: ellipsis; ///超出部分省略号显示

display: -webkit-box; //弹性盒模型

-webkit-box-orient: vertical; //上下垂直

-webkit-line-clamp: 3; //自定义行数
  }
  .aa {
    font-size: 20px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #4b83f0;
  }
  .a1 {
    font-size: 20px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #333333;
  }
  a2 {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  .img-class {
    width: 16px;
    height: 16px;
  }
  .img {
    display: flex;

    .imgs {
      width: 85px;
      height: 85px;
      img {
        width: 85px;
        height: 85px;
        //   background-color: pink;
      }
    }
    .right {
      padding-left: 20px;
      flex: 1;
      //   width: 590px;
      .name {
        width: 100%;
        // width: 590px;
        overflow: hidden; //超出文本隐藏

        text-overflow: ellipsis; ///超出部分省略号显示

        display: -webkit-box; //弹性盒模型

        -webkit-box-orient: vertical; //上下垂直

        -webkit-line-clamp: 2; //自定义行数

        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .color {
        margin-top: 10px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  /deep/ .el-checkbox :after {
    content: '' !important;
  }

  /deep/ .el-checkbox__inner::after {
    width: 6px;
    height: 14px;
    left: 7px;
  }

  /deep/ .el-checkbox__inner {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  /deep/ .el-table__header .has-gutter tr .el-table-column--selection .cell {
    position: relative;
    // display: flex;
    .el-checkbox::before {
      position: absolute;
      right: -10px;
      transform: translateX(100%);

      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      display: inline-block;
      content: '全选 ' !important;
    }
  }
  .header-logo {
    width: 341px;
    margin: 34px 0 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    img {
      width: 22px;
      height: 22px;
      background: #025edc;
    }
    .line {
      width: 2px;
      height: 22px;
      background-color: #e2e2e2;
      transform: rotate(20deg);
    }
    span {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .bottom-content {
    margin-top: 25px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    .content-left {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .content-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .right-info {
        display: flex;
        align-items: center;
        .right-num {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          span {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #20c197;
          }
        }
        .right-price {
          margin-left: 30px;
          margin-right: 30px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          span {
            font-size: 32px;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #333333;
          }
        }
      }
      .right-action {
        .button {
          width: 236px;
          height: 80px;
          background: #025edc;
          border-radius: 0px 2px 2px 0px;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #f9f4e4;
          line-height: 80px;
          text-align: center;
        }
      }
    }
  }
}
body {
  background: #f6f6f6;
}
</style>