<template>
  <div class="cart">
    <div class="header-logo">
      <i class="el-icon-s-custom" style="color: #1062d9"></i>
      <div class="line"></div>
      <span> {{$store.state.lang == 'en' ?'cart' :'购物车' }}</span>
    </div>

    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        :empty-text="$store.state.lang == 'en' ? 'No data yet' : '暂无数据' "
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="selection" width="80"> </el-table-column> -->
        <el-table-column :label="$store.state.lang == 'en' ? 'goods_name' :'商品名称'" width="420">
          <template slot-scope="scope">
            <div class="img">
              <div class="imgs"><img :src="scope.row.image" alt="" /></div>
              <div class="right">
                <div class="name">{{ $store.state.lang == 'en' ? scope.row.e_name : scope.row.name  }}</div>
                <div class="color">
                  <span>{{ $store.state.lang == 'en' ? scope.row.e_color :scope.row.color  }} {{ scope.row.xinghao  }}</span>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$store.state.lang == 'en' ? 'price' :'单价'" width="80">
          <template slot-scope="scope">
            <div>{{ $store.state.lang == 'en' ? '$' : '¥' }} {{ scope.row.price }}</div>
          </template>
        </el-table-column>
        <el-table-column width="300" align="center" :label="$store.state.lang == 'en' ? 'num' :'数量'" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="nums">
              <el-input-number
              class="nums"
                @change="
                  (val, val1) => {
                    handleChange(val, val1, scope.row)
                  }
                "
                @focus="handleChange"
                v-model="scope.row.num"
                :min="1"
                :precision="0"
                :max="1000000"
                label="描述文字"
              ></el-input-number>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :label="$store.state.lang == 'en' ? 'total' :'小记'"
          width="160"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <!-- @change="handleChange" -->
            <div>{{ $store.state.lang == 'en' ? '$' : '¥' }} {{ scope.row.num * scope.row.price  | numFilter}}</div>
          </template>
        </el-table-column>
        <el-table-column  align="center" :label="$store.state.lang == 'en' ? 'operate' :'操作'" show-overflow-tooltip>
          <template slot-scope="scope">
            <img
              style="cursor: pointer"
              @click="deletecart(scope.row.id)"
              class="img-class"
              src="@/assets/e-delete.png"
              alt=""
            />
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-if="tableData.length" class="bottom-content">
      <div class="content-left"></div>
      <div class="content-right">
        <div class="right-info">
          <div class="right-num" @click="deletecart(0)">
            <el-button type="primary"> {{$store.state.lang == 'en' ?'Delete Cart' :'清空购物车' }}  </el-button>
          </div>
          <div class="right-price">
            {{$store.state.lang == 'en' ? 'Total' :'合计'}}:
            <span> {{ $store.state.lang == 'en' ? '$' : '¥' }}{{ money | numFilter}}</span>
          </div>
        </div>
        <div class="right-action">
          <div style="cursor: pointer" @click="toAddOrder" class="button">
            {{$store.state.lang == 'en' ?'Submit Order' :'立即下单' }} 
          
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { json } from 'body-parser'

export default {
  name: 'User',
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      money: 0,
    }
  },
  created() {
    this.getCart()
    this.$forceUpdate()
  },
  activated() {
    this.getCart()
    this.$forceUpdate()
  },
  updated() {},
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f6f6f6'
    next()
  },
  filters: {
  numFilter (value) {
    // 截取当前数据到小数点后两位
    let realVal = parseFloat(value).toFixed(2)
    return realVal
  }
},

  methods: {
    toAddOrder() {
      this.$router.push({
        path: 'addorder',
      })
    },
    async getnum() {
      const { data: res } = await this.$http.post('api/user/cart_money')

      this.money = res.data.money
    },
    async handleChange(index1, index2, index3) {
      let params = {}
      params.type = index1 > index2 ? '1' : '0'
      params.color = index3.color
      params.goods_id = index3.goods_id
      params.num = index1

      const { data: res } = await this.$http.post('api/user/add_cart', params)

      if (res.code == 1) {
        const { data: res } = await this.$http.post('api/user/get_cart')
    this.getnum()
      let nums = 0
      res.data.forEach((item) => {
        nums += item.num
      })
      this.$store.commit('increase', {
        num: nums,
      })

      localStorage.setItem('num', nums)
        this.$message.success(res.msg)
    
        // this.getCart()
      }

    },
    async deletecart(id) {
      let params = {}
      params.cart_id = id
      const content = this.$store.state.lang == 'en' ? 'Whether to confirm the deletion of the address' : '是否确认清空购物车'
      const content1 = this.$store.state.lang == 'en' ? 'Whether to confirm the deletion of the product' : '是否确认删除该商品'
      const b1 =  this.$store.state.lang == 'en' ? 'sure' : '确定'
      const b2 =  this.$store.state.lang == 'en' ? 'cancel' : '取消'
      this.$confirm(   id == 0 ?  content :  content1, '', {
        confirmButtonText: b1,
        cancelButtonText: b2,
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.post(
            'api/user/del_cart',
            params
          )

          if (res.code == 1) {
            this.$message.success(res.msg)
            this.getCart()
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    async getCart() {
      const { data: res } = await this.$http.post('api/user/get_cart')
      // this.tableData =[{name:'2嘎3嘎是帝国大厦3不会的分公司法国大使馆的是富人变得根深蒂固的是',xinghao:'12gf',price:122,num:232}]
      this.tableData = res.data
      let nums = 0
      this.tableData.forEach((item) => {
        nums += item.num
      })
      this.$store.commit('increase', {
        num: nums,
      })

      localStorage.setItem('num', nums)
      this.getnum()

    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
  },
}
</script>
  
  <style lang="less" scoped>
/deep/ table thead .el-table_1_column_2 .cell {
  text-align: center !important;
}
.cart {
  .nums {
    margin: 0 auto;
    width: 160px;
    // width: 16px;
  }
  .img-class {
    width: 16px;
    height: 16px;
  }
  .img {
    display: flex;

    .imgs {
      width: 85px;
      height: 85px;
      img {
        width: 85px;
        height: 85px;
        // background-color: pink;
      }
    }
    .right {
      padding-left: 20px;
      flex: 1;
      .name {
        width: 100%;
        overflow: hidden; //超出文本隐藏

        text-overflow: ellipsis; ///超出部分省略号显示

        display: -webkit-box; //弹性盒模型

        -webkit-box-orient: vertical; //上下垂直

        -webkit-line-clamp: 2; //自定义行数

        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .color {
        margin-top: 10px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  /deep/ .el-checkbox :after {
    content: '' !important;
  }

  /deep/ .el-checkbox__inner::after {
    width: 6px;
    height: 14px;
    left: 7px;
  }
/deep/ .el-table .cell{
  line-height: normal
}
  /deep/ .el-checkbox__inner {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  /deep/ .el-table__header .has-gutter tr .el-table-column--selection .cell {
    position: relative;
    // display: flex;
    .el-checkbox::before {
      position: absolute;
      right: -10px;
      transform: translateX(100%);

      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      display: inline-block;
      content: '全选 ' !important;
    }
  }
  .header-logo {
    width: 141px;
    margin: 34px 0 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    img {
      width: 22px;
      height: 22px;
      background: #025edc;
    }
    .line {
      width: 2px;
      height: 22px;
      background-color: #e2e2e2;
      transform: rotate(20deg);
    }
    span {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .bottom-content {
    margin-top: 25px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    .content-left {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .content-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .right-info {
        display: flex;
        align-items: center;
        .right-num {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          span {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #20c197;
          }
        }
        .right-price {
          margin-left: 30px;
          margin-right: 30px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          span {
            font-size: 32px;
            font-family: Arial-BoldMT, Arial;
            font-weight: normal;
            color: #333333;
          }
        }
      }
      .right-action {
        .button {
          width: 236px;
          height: 80px;
          background: #025edc;
          border-radius: 0px 2px 2px 0px;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #f9f4e4;
          line-height: 80px;
          text-align: center;
        }
      }
    }
  }
}
body {
  background: #f6f6f6;
}
</style>