<template>
  <div class="home">
    <div class="header">
      <div class="header-container container-width">
        <div class="start">
          <!-- <div class="fd">LOGO</div> -->
          <img class="img" :src="imgLogo.pcs" alt="" />
          <div class="header-tabs">
            <ul>
              <li
                style="cursor: pointer"
                :class="tabsIndex == 1 ? 'active' : ''"
                @click="tabsClick(1)"
              >
                {{ $store.state.lang == 'en' ? 'buy' : '订货' }}
              </li>
              <li
                style="cursor: pointer"
                :class="tabsIndex == 2 ? 'active' : ''"
                @click="tabsClick(2)"
              >
                {{ $store.state.lang == 'en' ? 'order' : '订单' }}
              </li>
            </ul>
          </div>
          <div class="demo-input-suffix">
            <el-input
              style="cursor: pointer"
              @change="select"
              v-model="value"
              placeholder="search"
            >
              <i
                slot="suffix"
                class="el-input__icon el-icon-search"
                @click="select()"
                style="cursor: pointer"
              ></i>
            </el-input>
          </div>

          <div style="cursor: pointer" @click="toCart" class="header-cart">
            <el-badge :value="$store.state.num" class="item">
              <img src="@/assets/cart.png" alt="" />
            </el-badge>
          </div>
          <div
            style="display: flex; align-items: center"
            class="language-change"
          >
            <div style="margin-right: 20px">
              <img
                v-if="$store.state.lang == 'en'"
                @click="changelang('en')"
                class="a1"
                style="cursor: pointer"
                src="@/assets/en_active.png"
              />
              <img
                v-else
                class="a2"
                @click="changelang('en')"
                style="cursor: pointer"
                src="@/assets/ying.png"
              />
            </div>
            <div>
              <img
                class="b1"
                v-if="$store.state.lang == 'ch'"
                @click="changelang('ch')"
                style="cursor: pointer"
                src="@/assets/zhong.png"
              />
              <img
                class="b2"
                v-else
                @click="changelang('ch')"
                style="cursor: pointer"
                src="@/assets/ch.png"
              />
            </div>
          </div>
        </div>
        <div style="cursor: pointer" @click="toUser" class="user-photo">
          <img
            style="width: 100%; height: 100%; border-radius: 50%"
            :src="url"
            alt=""
          />
          <!-- <el-avatar ></el-avatar> -->
        </div>
      </div>
    </div>

    <div class="container-width">
      <keep-alive>
        <router-view ref="child"></router-view>
      </keep-alive>
    </div>

    <div class="aotuheight"></div>
    <div class="footer">
      <div class="footer-main container-width">
        <div><img class="img" :src="imgLogo.pcx" alt="" /></div>
          <span><a style="text-decoration:none" href="https://beian.miit.gov.cn/#/Integrated/index">{{ imgLogo.beian }}</a></span>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  data() {
    return {
      chA: '@/assets/zhong.png',
      ch: '@/assets/ch.png',
      en: '@/assets/ying.png',
      enA: '@/assets/en-active.png',

      value: '',
      imgLogo: {},
      tabsIndex: 1,
      num: localStorage.getItem('num'),
      url: 'http://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
    }
  },
  created() {
    let index = sessionStorage.getItem('tabindex')

    index ? (this.tabsIndex = index) : 1
    this.getimg()
  },
  methods: {
    async getimg() {
      const { data: res } = await this.$http.post('api/index/getconfig')
      this.imgLogo = res.data
      console.log(this.imgLogo)
    },
    changelang(lang) {
      this.$store.commit('getlang', {
        lang: lang,
      })
      localStorage.setItem('lang', lang)
    },
    select() {
      if (this.$refs.child.geta) {
        this.$refs.child.geta(this.value)
      }
    },
    toCart() {
      this.$router.push({ path: '/cart' })
    },
    tabsClick(index) {
      this.tabsIndex = index
      if (index == 1) {
        this.$router.push({ path: '/index' })
      } else {
        this.$router.push({ path: '/order' })
      }
      sessionStorage.setItem('tabindex', index)
    },
    toUser() {
      this.$router.push({ path: '/user' })
    },
  },
}
</script>
<style lang="less" >
.home {
  body {
    background: #f6f6f6;
  }
  .fd {
    font-size: 36px;
    font-family: Asap-BoldItalic, Asap;
    font-weight: normal;
    color: #ffffff;
  }
  .header {
    width: 100%;
    height: 66px;
    position: relative;
    background: #025edc;
    border-radius: 1px;
    .el-input {
      width: 100%;
      height: 100%;
    }
    .el-input__inner {
      font-size: 18px;
      height: 100%;
      .el-input__inner {
        font-size: 18px;
        height: 100%;
      }
    }
    .header-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      .start {
        display: flex;
        align-items: center;
        flex: 1;
        .img {
          width: 148px;
          height: auto;
          // height: 45px;
        }
        .header-tabs {
          margin-left: 73px;
          ul {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            .active {
              position: relative;
            }
            .active::after {
              width: 32px;
              height: 5px;
              background: #20c197;
              border-radius: 4px;
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 100%);
            }
            li:nth-child(1) {
              margin-right: 60px;
            }
          }
        }
        .demo-input-suffix {
          width: 260px;
          height: 42px;
          // background: #FFFFFF;
          border-radius: 4px;
          margin-left: 246px;
        }
        .header-cart {
          /deep/ .el-badge__content {
            // width: 14px;
            height: 14px;
            background: #20c197;
            border-radius: 7px;
            padding: 0 8px;
            text-align: center;
            border: 0;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
          }
          img {
            margin-left: 36px;
            width: 25px;
            height: 24px;
            // background: black;
          }
        }
        .language-change {
          margin-left: 66px;
          .a1 {
            width: 28px;
            height: 24px;
          }
          .a2 {
            width: 24px;
            height: 16px;
          }
          .b1 {
            width: 28px;
            height: 26px;
          }
          .b2 {
            width: 22px;
            height: 20px;
          }
          img:nth-child(1) {
            // width: 22px;
            // height: 26px;
            // background: black;
          }
          img:nth-child(2) {
            // margin-left: 25px;
            // width: 28px;
            // height: 20px;
            // background: black;
          }
        }
      }
      .user-photo {
        width: 42px;
        height: 42px;
        border-radius: 50%;

        justify-content: flex-end;
      }
    }
  }
  .aotuheight {
    height: 66px;
  }
  .footer {
    position: fixed;
    z-index: 99;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 66px;
    background: #333333;
    border-radius: 1px;
    .footer-main {
      width: 1151px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div:nth-child(1) {
        font-size: 36px;
        font-family: Asap-BoldItalic, Asap;
        font-weight: normal;
        color: #ffffff;
        image {
          width: 148px;
          height: auto;
        }
      }
      span:nth-child(2) {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }
    }
  }
}
</style>
