<template>
  <div class="login_container" :style="{ backgroundImage: `url(${bimage})` }">
    <div class="login_box">
      <!--      头像区域-->
      <div class="avatar_box">
        {{ $store.state.lang == 'en' ? 'Login' : '账号密码登录' }}
      </div>
      <el-divider></el-divider>
      <!--      表单提交区域-->
      <el-form
        :rules="loginFormRules"
        ref="loginFormRef"
        label-width="0px"
        class="login_form"
        :model="loginForm"
      >
        <!--        用户名-->
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.account"
            :placeholder="
              $store.state.lang == 'en'
                ? 'Please enter the account number'
                : '请输入账号'
            "
            prefix-icon="iconfont icon-user"
            class="a"
          ></el-input>
        </el-form-item>
        <!--        密码-->
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="loginForm.password"
            :placeholder="
              $store.state.lang == 'en'
                ? 'Please enter the password'
                : '请输入密码'
            "
            prefix-icon="iconfont icon-3702mima"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-checkbox v-model="radio" label="ji">记住账号密码</el-checkbox>
        </el-form-item> -->
        <el-form-item>
          <el-radio @input="changelang('en')" v-model="radios" label="1"
            >English</el-radio
          >
          <el-radio @input="changelang('ch')" v-model="radios" label="2"
            >简体中文</el-radio
          >
        </el-form-item>

        <!--        按钮区-->
        <el-form-item>
          <el-button style="background-color: #FFCC33;color: black;border: 0;" type="primary" @click="login">{{
            $store.state.lang == 'en' ? 'Sign in now' : '立即登录'
          }}</el-button>
          <!-- <el-button type="info" @click="resetLoginForm">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <div class="footer-main container-width">
      
        <!-- <div><img class="img" :src="imgLogo.pcx" alt="" /></div> -->
        <span><a href="https://beian.miit.gov.cn/#/Integrated/index">{{ beian }}</a></span>
      </div>
    </div>
  </div>
</template>
<script>
import { json } from 'body-parser'

export default {
  name: 'Login',
  data() {
    return {
      radios: '1',
      radio: '',
      bimage: '',
      beian:'',
      loginLoading: false, // 登录限制
      loginForm: {
        // 登录的表单数据的绑定对象
        account: '',
        password: '',
      },
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          // { required: true, message: '请输入登录名称', trigger: 'blur' },
          // {
          //   min: 3,
          //   max: 100,
          //   message: '长度在 3 到 10 个字符',
          //   trigger: 'blur',
          // },
        ],
        // 验证密码是否合法
        password: [
          // { required: true, message: '请输入登录密码', trigger: 'blur' },
          // {
          //   min: 6,
          //   max: 15,
          //   message: '长度在 6 到 15 个字符',
          //   trigger: 'blur',
          // },
        ],
      },
    }
  },
  created() {
    let lang = localStorage.getItem('lang')
    if (lang == 'en') {
      this.radios = '1'
    } else {
      this.radios = '2'
    }
    this.getimg()
  },
  mounted() {
    // let zhanghao = JSON.parse(localStorage.getItem('zhanghao'))
    // console.log(zhanghao)
    // if (zhanghao) {
    //   this.loginForm = zhanghao
    // }
  },
  methods: {
    async getimg() {
      const { data: res } = await this.$http.post('api/index/getconfig')
      this.bimage = res.data.pcimage
      this.beian = res.data.beian
    },
    resetLoginForm() {
      // 点击重置按钮,重置登录表单
      // this.$refs[loginFormRef].resetFields()
      this.$refs.loginFormRef.resetFields()
    },
    changelang(lang) {
      this.$store.commit('getlang', {
        lang: lang,
      })
      localStorage.setItem('lang', lang)
    },
    async login() {
      if (this.radios == 1) {
        localStorage.setItem('lang', 'en')
        this.$store.commit('getlang', {
          lang: 'en',
        })
      } else {
        localStorage.setItem('lang', 'ch')
        this.$store.commit('getlang', {
          lang: 'ch',
        })
      }
      this.loginLoading = true
      const { data: res } = await this.$http.post(
        'api/user/login',
        this.loginForm
      )

      if (res.code !== 1) {
        this.loginLoading = false
        return this.$message.error(res.msg)
      }
      this.$message.success('登录成功!')
      // 1. 将登录成功之后的 token,保存到客户端的 sessionStorage(会话机制/只在当前页面生效)中 localStorage(持久话机制/关闭页面也不会忘记数据)
      //   1.1 项目中除了登录之外的API接口,必须在登录之后才能访问
      //   1.2 token 只应在当前网站打开期间生效, 所以将 token 保存在 sessionStorage中
      localStorage.setItem('token', res.data.userinfo.token)
      localStorage.setItem('userinfo', JSON.stringify(res.data.userinfo))
      // if (this.radio) {
      //   localStorage.setItem('zhanghao', JSON.stringify(this.loginForm))
      // }
      // 2. 通过编程式路由导航跳转到后台主页,路由地址是 /home
      this.$router.push('/home')

      // this.$refs.loginFormRef.validate(async (valid) => {
      //   if (!valid) {
      //     return (this.loginLoading = false)
      //   }

      //   this.loginLoading = false
      // })
    },
  },
}
</script>
<style lang="less" scoped>
  .footer {
    position: fixed;
    z-index: 99;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 66px;
    background: #333333;
    border-radius: 1px;
    .footer-main {
      width: 1151px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      div:nth-child(1) {
        font-size: 36px;
        font-family: Asap-BoldItalic, Asap;
        font-weight: normal;
        color: #ffffff;
        image {
          width: 148px;
          height: auto;
        }
      }
      span:nth-child(1) {
        line-height: 66px;
        display: inline-block;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;      
        font-weight: 500;
      }
    }
  }
.login_container {
  height: 100%;
  background-size:  100vw 100vh;

  background-repeat: no-repeat;
}
.el-divider--horizontal {
  margin-top: 9px !important;
}
.el-form-item {
  //   width: 384px;
  // height: 48px;

  border-radius: 2rpx;
}

.login_box {
  position: absolute;
  top: 50%;
 left: 50%;
  transform: translate(-50%,-50%);
  width: 486px;
  box-sizing: border-box;

  background-color: #fff;
  border-radius: 3px;
  .avatar_box {
    margin-top: 42px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    text-align: center;
  }
  .el-radio__inner {
    border-radius: 2px !important;
  }
  .login_form {
    box-sizing: border-box;

    margin-top: 5px;
    width: 100%;
    padding: 0 51px;
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
  .el-button {
    width: 100%;

    height: 50px;
    background: #025edc;
    border-radius: 4px;
    margin-bottom: 87px;
  }
}
</style>
